import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { setupCalendar } from 'v-calendar';

import { initSentry } from '@/sentry';
import { keycloakApi } from '@/connection/keycloak-api';
import { useNutzerStore } from '@/shared/Nutzer/useNutzerStore';
import { countdownService } from '@/service/CountdownService';

// Importiere virtuelles Sentry Config Module vom vite Plugin.
// Damit werden die "dist" und "release" Settings des Build Prozesses genutzt
import 'virtual:vite-plugin-sentry/sentry-config';

import App from './App.vue';
import router from './router';

import '@/shared/Validierung/initYup';
// Import unserer Styles
import '../public/static/assets/style.css';
import 'v-calendar/style.css';

const app = createApp(App);
const pinia = createPinia();

initSentry(app);

keycloakApi
  .init()
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      app.use(pinia);
      app.use(router);
      app.use(setupCalendar, {});
      useNutzerStore().ladeNutzer();

      app.mount('#app');
      // Start des Session-Timers
      countdownService.start();
    }
  })
  .catch(() => {});

// Start des Session-Timers
countdownService.start();
