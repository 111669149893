import type { RouteRecordRaw } from 'vue-router';
import { ANZEIGENAME, KATEGORIE } from '@/persistence/RechteStorage';

const bspMitarbeiterRoutes: RouteRecordRaw = {
  path: '/bspGmbh',
  component: () => import('./BspMitarbeiter.vue'),
  name: 'bspGmbh',
  children: [
    /** AUFGABEN */
    {
      path: 'aufgaben/liste',
      name: 'aufgabenListeTrustlog',
      component: () => import('./components/aufgaben/AufgabenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.AUFGABEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    /** BÜRGSCHAFTSANTRÄGE */

    /** Liste aller Bürgschaftsanträge */
    {
      path: 'buergschaftsauftraege/liste',
      name: 'buergschaftsauftraegeListeTrustlog',
      component: () => import('./components/buergschaftsauftraege/BuergschaftsauftragListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    /** Bürgschaftsantrag mit den Details anzeigen */
    {
      path: 'buergschaftsauftraege/details/:buergschaftsauftragId(\\d+)',
      name: 'buergschaftsauftragDetailsTrustlog',
      props: true,
      component: () => import('./components/buergschaftsauftraege/BuergschaftsauftragDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    /** BÜRGSCHAFTEN */
    {
      path: 'buergschaften/liste',
      name: 'bürgschaftenListeTrustlog',
      component: () => import('./components/buergschaften/BuergschaftenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaften/details',
      name: 'bürgschaftDetailsTrustlog',
      component: () => import('./components/buergschaften/BuergschaftDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaften/hochladen/csv',
      name: 'buergschaftenCsvHochladenBsp',
      component: () => import('./components/buergschaften/BuergschaftCsvHochladen.vue'),
      props: true,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.HOCHLADEN,
      },
    },
    {
      path: 'buergschaften/hochladen/csv/ergebnis',
      name: 'buergschaftenCsvHochladenErgebnisBsp',
      component: () => import('./components/buergschaften/BuergschaftCsvHochladenErgebnis.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.HOCHLADEN,
      },
    },

    /** BÜRGSCHAFTSTEXTE */
    {
      path: 'buergschaftstexte/liste',
      name: 'bürgschaftstexteListeTrustlog',
      component: () => import('./components/buergschaftstexte/BuergschaftstexteListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/details',
      name: 'auftraggebertextDetailsTrustlog',
      props: true,
      component: () => import('./components/buergschaftstexte/AuftraggebertextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/buergentext/details',
      name: 'bürgentextDetailsTrustlog',
      component: () => import('./components/buergschaftstexte/BuergentextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/oeffentlichertext/details',
      name: 'öffentlicherTextDetailsTrustlog',
      component: () => import('./components/buergschaftstexte/OeffentlicherTextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/loeschen',
      name: 'bürgschaftstextLöschenTrustlog',
      component: () =>
        import(
          './components/buergschaftstexte/common/auftraggebertexte/aktionen/BuergschaftstextLoeschen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.LÖSCHEN,
      },
    },
    {
      path: 'buergschaftstexte/textartauswahl',
      name: 'bürgschaftstextHochladenTextartAuswahlTrustlog',
      component: () =>
        import('./components/buergschaftstexte/BuergschaftstextAnlegenTextartAuswahl.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.BEARBEITEN,
      },
    },
    {
      path: 'buergschaftstexte/anlegen',
      name: 'bürgschaftstextAnlegenTrustlog',
      component: () => import('./components/buergschaftstexte/BuergschaftstextAnlegen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.BEARBEITEN,
      },
    },
    {
      path: 'buergschaftstexte/anlegen/zusammenfassung',
      name: 'bürgschaftstextAnlegenZusammenfassungTrustlog',
      component: () =>
        import('./components/buergschaftstexte/BuergschaftstextAnlegenZusammenfassung.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.BEARBEITEN,
      },
    },
    {
      path: 'buergschaftstexte/anlegen/erfolg',
      name: 'bürgschaftstextAnlegenErfolgTrustlog',
      component: () => import('./components/buergschaftstexte/BuergschaftstextAnlegenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.BEARBEITEN,
      },
    },

    /** STAMMDATEN */
    {
      path: 'stammdaten/liste',
      name: 'stammdatenUnternehmenListeTrustlog',
      component: () => import('./components/stammdaten/StammdatenUnternehmenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'stammdaten/uebersicht',
      name: 'stammdatenUnternehmenÜbersichtTrustlog',
      component: () => import('./components/stammdaten/StammdatenUnternehmenUebersicht.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'stammdaten/unternehmen/anlegen',
      name: 'unternehmenErstellenTrustlog',
      component: () => import('./components/stammdaten/StammdatenUnternehmenErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'stammdaten/buergen/anlegen',
      name: 'bürgeErstellen',
      component: () => import('./components/stammdaten/buerge/StammdatenBuergeErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'stammdaten/unternehmen/bearbeiten',
      name: 'unternehmenBearbeitenTrustlog',
      component: () => import('./components/stammdaten/StammdatenUnternehmenBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'stammdaten/buergen/bearbeiten',
      name: 'bürgeBearbeiten',
      component: () => import('./components/stammdaten/buerge/StammdatenBuergeBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'stammdaten/buergen/anzeigedaten/bearbeiten',
      name: 'bürgeAnzeigedatenBearbeiten',
      props: true,
      component: () =>
        import('./components/stammdaten/buerge/StammdatenBuergeAnzeigedatenBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'stammdaten/gesellschaft/bearbeiten',
      name: 'gesellschaftBearbeitenTrustlog',
      component: () => import('./components/stammdaten/StammdatenGesellschaftBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'stammdaten/gesellschaft/freigeben',
      name: 'gesellschaftFreigebenTrustlog',
      component: () => import('./components/stammdaten/StammdatenGesellschaftFreigeben.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'stammdaten/unternehmen/gevo/uebersicht',
      name: 'gevosÜbersicht',
      component: () => import('./components/stammdaten/gevos/GevosUebersicht.vue'),
      meta: {
        kategorie: [KATEGORIE.GEVOS],
        anzeigeName: ANZEIGENAME.BEARBEITEN,
      },
    },
    {
      path: 'stammdaten/buerge/gevo/bearbeiten/default-mail',
      name: 'gevoDefaultEmailBearbeiten',
      component: () => import('./components/stammdaten/gevos/BuergeGevoDefaultEmailBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.GEVOS],
        anzeigeName: ANZEIGENAME.BEARBEITEN,
      },
    },
    {
      path: 'stammdaten/unternehmen/gevo/bearbeiten',
      name: 'gevoBearbeiten',
      component: () => import('./components/stammdaten/gevos/GevoBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.GEVOS],
        anzeigeName: ANZEIGENAME.BEARBEITEN,
      },
    },

    {
      path: 'stammdaten/nutzer/anlegen',
      name: 'adminNutzerErstellenTrustlog',
      component: () => import('./components/stammdaten/nutzer/AdminNutzerErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'stammdaten/nutzer/anlegen/erfolg',
      name: 'adminNutzerErstellenErfolgTrustlog',
      component: () => import('./components/stammdaten/nutzer/AdminNutzerErstellenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'stammdaten/nutzer/bearbeiten',
      name: 'adminNutzerBearbeitenTrustlog',
      component: () => import('./components/stammdaten/nutzer/AdminNutzerBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'stammdaten/buergen/liste',
      name: 'buergeListeTrustlog',
      component: () => import('@/bsp/components/stammdaten/buerge/StammdatenBuergeListe.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** NUTZER UND ROLLEN */
    {
      path: 'nutzer/liste/:aktiverTab?',
      name: 'nutzerRollenListeTrustlog',
      component: () => import('./components/nutzer/NutzerRollenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER, KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'nutzer/anlegen',
      name: 'nutzerErstellenTrustlog',
      component: () => import('./components/nutzer/NutzerErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/wiederherstellen',
      name: 'nutzerWiederherstellenTrustlog',
      component: () => import('./components/nutzer/NutzerWiederherstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/:nutzerId([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/bearbeiten',
      name: 'nutzerBearbeitenTrustlog',
      component: () => import('./components/nutzer/NutzerBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'nutzer/rollen/anlegen',
      name: 'rolleErstellenTrustlog',
      component: () => import('./components/nutzer/RolleErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/rollen/bearbeiten',
      name: 'rolleBearbeitenTrustlog',
      component: () => import('./components/nutzer/RolleBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },

    /** KONTOÜBERSICHT */
    {
      path: 'kontouebersicht',
      name: 'kontoÜbersichtTrustlog',
      component: () => import('../shared/Nutzer/Components/KontoUebersicht.vue'),
    },

    /** ERROR SCREEN FEHLENDE RECHTE */
    {
      path: 'error',
      name: 'fehlerScreenFehlendeRechteTrustlog',
      component: () => import('./components/common/FehlerScreenFehlendeRechte.vue'),
    },
  ],
};

export { bspMitarbeiterRoutes };
