import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import { configForDownload } from '@/connection/base';

export class SharedDokumentService extends HttpService {
  protected dokumentServiceBaseUrl = `${buildUrlForServiceName(conf.dokumentserviceName)}/dokumentservice/api/private/v2/auftraggeber`;

  async holeBuergschaftPdf(buergschaftId: number): Promise<BlobPart> {
    return this.httpClient.get(
      `${this.dokumentServiceBaseUrl}/buergschaftsdokumente/buergschaft_${buergschaftId}.pdf`,
      { config: configForDownload },
    );
  }
}
